<template>
    <div class="gb-query">
        <section>
            <div
                class="gb-query-group"
                :class="{ 'group-unfold': isShowDrawer || foldState }"
            >
                <slot :query="queryForm" :handle-search="handleSearch"></slot>
            </div>
            <div class="gb-query-btns">
                <span
                    v-if="isShowFold"
                    class="flod"
                    :class="{ unfold: foldState }"
                    @click="handleFold"
                >
                    {{ foldText }}
                    <i class="iconfont icon-xiala"></i>
                </span>
                <span
                    v-if="isShowDrawer"
                    class="flod"
                    @click="handleDrawer(true)"
                >
                    更多筛选
                </span>
                <el-button
                    type="primary"
                    round
                    class="custom-btn"
                    @click="handleSearch"
                >
                    查询
                </el-button>
                <el-button round class="custom-btn" @click="handleReset(false)"> 重置 </el-button>
            </div>
        </section>
        <div v-if="moreFilterVisible" class="more-filter">
            筛选条件：
            <div v-for="(item, key, index) in moreFilters" :key="key">
                {{ index === 0 ? '' : '，' }}{{ item }}
            </div>
        </div>
        <el-drawer
            custom-class="custom-drawer"
            title="更多筛选"
            :visible.sync="drawer"
            :direction="direction"
            :before-close="handleDrawerClose"
            :with-header="false"
        >
            <div class="content">
                <slot name="drawer" :query="queryForm" :drawer-query-view="drawerQueryView"></slot>
            </div>
            <div class="demo-drawer__footer">
                <el-button @click="handleDrawerReset">取 消</el-button>
                <el-button type="primary" @click="handleDrawerSearch">确 定</el-button>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import _ from 'lodash'

export default {
    name: 'SearchQuery',
    props: {
        query: {
            type: Object,
            default: () => {}
        },
        defaultQuery: {
            type: Object,
            default: () => {}
        },
        drawerQueryField: {
            type: Array, // // 抽屉不重置的字段。抽屉取消和查询重置操作不太一样。取消的时候回复到原来的筛选条件
            default: () => []
        },
        drawerQuery: {
            type: Object,
            default: () => {}
        },
        moreFilters: {
            type: Object,
            default: () => {}
        },
        isShowFold: {
            type: Boolean,
            default: true
        },
        isShowDrawer: {
            type: Boolean,
            default: false
        },
        direction: {
            type: String,
            default: 'rtl'
        }
    },
    data () {
        return {
            foldState: false,
            queryForm: {},
            defaultQueryForm: {
                current: 1,
                size: 10
            },
            drawer: false,
            drawerQueryView: {}
        }
    },
    computed: {
        foldText () {
            return this.foldState ? '收起' : '展开'
        },
        moreFilterVisible () {
            if (!this.isShowDrawer || _.isEmpty(this.moreFilters)) {
                return false
            }
            return true
        }
    },
    watch: {
        query: {
            immediate: true,
            deep: true,
            handler (newVal) {
                this.queryForm = _.cloneDeep(newVal)
            }
        },
        drawerQuery: {
            immediate: true,
            deep: true,
            handler (newVal) {
                this.drawerQueryView = _.cloneDeep(newVal)
            }
        }
    },
    methods: {
        handleFold () {
            this.foldState = !this.foldState
        },
        handleReset (isDrawer) {
            // todo:查询条件置空
            Object.keys(this.queryForm).forEach(item => {
                if (isDrawer) {
                    // 如果为抽屉，则还原原本内容
                    if (this.drawerQueryField.includes(item)) {
                        this.queryForm[item] = this.query[item]
                    }
                } else {
                    this.queryForm[item] = null
                }
            })
            if (!isDrawer) {
                const queryForm = {
                    ...this.queryForm,
                    ...this.defaultQueryForm,
                    ...this.defaultQuery
                }
                this.queryForm.size = 10
                this.queryForm.current = 1
                this.$emit('handle-reset', queryForm)
            } else {
                Object.keys(this.drawerQueryView).forEach(item => {
                    this.drawerQueryView[item] = this.drawerQuery[item]
                })
                this.$emit('handle-drawer-reset', this.drawerQueryView)
            }
        },
        handleSearch () {
            // 请求查询接口
            this.$emit('handle-search', this.queryForm)
            if (this.isShowDrawer) {
                this.$emit('handle-drawer-reset', this.drawerQueryView)
            }
        },
        handleDrawerReset () {
            // 取消抽屉填写的内容
            this.handleReset(true)
            this.handleDrawer(false)
        },
        handleDrawerSearch () {
            // 抽屉内容确认
            this.handleDrawer(false)
            this.handleSearch()
        },
        handleDrawerClose () {
            this.handleDrawer(false)
        },
        handleDrawer (val = true) {
            this.drawer = val
        }
    }
}
</script>
<style lang="scss">
.custom-drawer {
    .el-drawer__body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .content {
        flex: 1;
        padding: 0.15rem;
        overflow-y: auto;
    }

    .demo-drawer__footer {
        padding: 0.1rem;
        text-align: right;
    }
}
</style>
<style lang="scss" scoped>
.gb-query {
    .w240 {
        width: 240px;
    }

    .w160 {
        width: 160px;
    }

    .w340 {
        width: 340px;
    }

    .mr40 {
        margin-right: 40px;
    }

    .mr20 {
        margin-right: 20px;
    }

    & > section {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &-group {
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        max-height: 52px;
        overflow: hidden;
        transition: all 0.3s;

        & > div {
            margin-bottom: 12px;
        }

        ::v-deep.el-checkbox {
            padding-top: 10px;
        }

        .textarea-group {
            display: flex;
        }
    }

    .group-unfold {
        max-height: 100%;
    }

    &-btns {
        min-width: 194px;

        .flod {
            display: inline-flex;
            margin-right: 8px;
            font-size: 14px;
            font-weight: 400;
            color: #3595e1;
            cursor: pointer;
        }

        .flod i {
            display: flex;
            align-items: center;
            transition: all 0.3s;
            transform: scale(0.6);
        }

        .unfold i {
            transform: scale(0.6) rotate(180deg);
        }

        .custom-btn {
            padding: 12px 18px;
            font-size: 14px;
            border: 1px solid #dcdfe6;
            border-radius: 8px;

            & + btn {
                background: #3595e1;
            }
        }
    }
}

.more-filter {
    display: flex;
    flex-wrap: wrap;
    margin: 0.05rem 0 0.19rem;
    font-size: 0.14rem;
    font-weight: 400;
    color: #606266;

    & > div {
        max-width: 5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
</style>
